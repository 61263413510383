import { AuthorizationService } from '$/app/services';
import { FeatureFlag } from '$shared/constants/feature-flags';
import { inject } from '@angular/core';

export const hasFeatureFlags = (...flags: FeatureFlag[]) => {
  return () => {
    const authorize = inject(AuthorizationService);

    return authorize.hasFeatureFlags(...flags);
  };
};
